import { useHistory } from 'react-router'

const createLinkButtonProps = (url) => {
  const history = useHistory()

  return {
    onClick: (e) => {
      if (!e.ctrlKey && !e.metaKey) {
        e.preventDefault()
        history.push(url)
      }
    },
    href: url
  }
}

export default createLinkButtonProps

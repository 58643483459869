import React, { useState } from 'react'
import Jira from 'mdi-material-ui/Jira'
import { IconButton, TextField, Card, CardContent, CircularProgress, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useHistory } from 'react-router'
import JiraDetails from './jira_details'
import { useQuery, useLazyQuery } from '@apollo/client'
import { FETCH_JIRA_ISSUES_FOR_AUTOCOMPLETE, FETCH_JIRA_VERSIONS } from '../api/jira'

const StyledCard = styled(Card)`
  & .MuiCardContent-root {
    padding: 16px;
  }
`

const Form = styled('form')`
  display: flex;
  flex-direction: row;
`

const StyledAutocomplete = styled(Autocomplete)`
  display: flex;
  flex: 1;
  width: 100%;
`

const StyledInput = styled(TextField)`
  flex: 1;
  width: 100%;
`

const IssueName = styled(Typography)`
  margin-right: ${(props) => props.theme.spacing(0.5)};
`

const JiraForm = ({ children, ...props }) => {
  const { loading, data: suggestions } = useQuery(FETCH_JIRA_ISSUES_FOR_AUTOCOMPLETE)
  const [getIssueVersions, { loading: issueVersionsLoading }] = useLazyQuery(FETCH_JIRA_VERSIONS)

  const [issue, setIssue] = useState('')
  const [selectedOption, setSelectedOption] = useState(null)
  const history = useHistory()

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.key} ${option.title} ${option.team} ${option.url}`
  })

  const onSubmit = async (event) => {
    event.preventDefault()
    try {
      const url = typeof issue === 'string' ? issue : issue.url
      const versions = await getIssueVersions({ variables: { key: url } })

      if (versions.data?.jiraIssueVersions) {
        history.push('/create', { ...versions.data.jiraIssueVersions, url })
      }
    } catch (exception) {
      console.log(exception)
    }
  }

  return (
    <StyledCard {...props}>
      <CardContent>
        <Form onSubmit={onSubmit}>
          <StyledAutocomplete
            autoComplete
            freeSolo
            filterOptions={filterOptions}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.url)}
            options={suggestions?.jiraIssues || []}
            includeInputInList
            value={selectedOption}
            onChange={(e, value) => setSelectedOption(value)}
            onInputChange={(e, value) => setIssue(value)}
            fullWidth
            renderInput={(params) => {
              // filter out props that do not work with the BaseInput
              const {
                InputProps: { startAdornment, endAdornment, ...InputProps }
              } = params

              return (
                <StyledInput
                  {...InputProps}
                  size='small'
                  variant='outlined'
                  inputProps={params.inputProps}
                  placeholder='Copy JIRA url here or start typing the ticket number, title or team name...'
                />
              )
            }}
            renderOption={(props, option) => {
              return (
                <Grid container {...props}>
                  <IssueName variant='subtitle1' display='inline'>
                    {option.key}
                  </IssueName>
                  <Typography variant='subtitle1' display='inline' color='textSecondary'>
                    {option.title}
                  </Typography>
                  <JiraDetails team={option.team} status={option.status} priority={option.priority} />
                </Grid>
              )
            }}
          />
          <IconButton onClick={onSubmit} disabled={loading || issueVersionsLoading} size='medium'>
            {loading || issueVersionsLoading ? <CircularProgress size={24} /> : <Jira />}
          </IconButton>
        </Form>
      </CardContent>
    </StyledCard>
  )
}

JiraForm.propTypes = {}

export default JiraForm

import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useHistory } from 'react-router'
import { useMutation } from '@apollo/client'
import { styled, useTheme } from '@mui/material/styles'
import { Button, Grid, Container, CircularProgress, useMediaQuery } from '@mui/material'
import RocketLaunch from 'mdi-material-ui/RocketLaunch'
import { useSnackbar } from 'notistack'
import { useSetState } from 'rooks'
import { DEPLOYMENT_CREATE } from '../../../api/deployments'
import { useConfig } from '../../../config'
import { PRIMARY } from '../../../constants/brand'
import { Deployment } from '../../../models'
import { useStore } from '../../../store'
import JiraIssue from '../../jira_issue'
import Title from '../../title'
import SimplifiedCard from '../../simplified_card'
import JiraForm from '../../jira_form'
import Instances from './instances'
import Apps from './apps'
import Warnings from './warnings'
import Checkboxes from './checkboxes'

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${(props) => props.theme.spacing(3)};
`

const Create = () => {
  const [createDeployment, { loading, error }] = useMutation(DEPLOYMENT_CREATE)
  const config = useConfig()
  const {
    session: { currentUser }
  } = useStore()
  const theme = useTheme()
  const condensedLayout = useMediaQuery(theme.breakpoints.down('md'))

  const location = useLocation()

  const history = useHistory()
  const [jiraIssue, setJiraIssue] = useState(null)
  const [displayedWarningsSet, controlDisplayedWarnings] = useSetState([])
  const displayedWarnings = Array.from(displayedWarningsSet).reverse()

  const displayWarning = (warning) => controlDisplayedWarnings.add(warning)
  const closeWarning = (warning) => controlDisplayedWarnings.delete(warning)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    const url = location?.state?.url || ''
    const match = url.match(/https:\/\/helpling\.atlassian\.net\/browse\/([^/]+)\/?/)

    if (match && match[1]) {
      setJiraIssue(match[1])
    }
  }, [location?.state?.url])

  const startDeployment = async () => {
    closeSnackbar()

    try {
      if (deployment.apps.length === 0) {
        throw new Error('Cannot trigger an empty deployment')
      }

      if (deployment.instances.length === 0) {
        throw new Error('Cannot trigger a deployment without testing instances')
      }

      const result = await createDeployment({
        variables: {
          username: currentUser.email,
          jiraIssue,
          ...deployment.toGraphQLInput()
        }
      })

      const id = result?.data?.deploymentCreate?.deployment?.id
      if (id) {
        history.push(`/build/${id}`)
      }
    } catch (e) {
      // Ignore GraphQL errors as they're handled displayed elsewhere
      if (!Object.getOwnPropertyDescriptor(e, 'graphQLErrors')) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
      console.error(e)
    }
  }

  const deployment = useState(() => Deployment.create())[0]

  useEffect(() => {
    if (deployment) {
      const versions = location?.state?.versions
      const warnings = location?.state?.warnings
      if (versions) {
        deployment.setFromJiraIssueVersions(versions, config.apps)
      }
      if (warnings) {
        warnings.forEach((warning) => displayWarning({ message: warning, variant: 'warning' }))
      }
    }
  }, [location?.state?.versions])

  useEffect(() => {
    if (deployment) {
      const instances = location?.state?.instances

      if (instances) {
        deployment.setInstances(instances)
      }
    }
  }, [location?.state?.instances])

  return (
    <Container>
      <Title title='New deployment' colors={[PRIMARY, PRIMARY]} />
      <Grid container spacing={3}>
        <Grid item md={7} sm={12} xs={12}>
          {condensedLayout && (jiraIssue ? <JiraIssue issue={jiraIssue} /> : <JiraForm sx={{ marginBottom: 3 }} />)}
          <Apps
            apps={config.apps}
            deployment={deployment}
            sx={condensedLayout ? { mb: 0 } : {}}
            condensed={condensedLayout}
          />
          {!condensedLayout && <Warnings warnings={displayedWarnings} closeWarning={closeWarning} />}
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          {!condensedLayout && (jiraIssue ? <JiraIssue issue={jiraIssue} /> : <JiraForm sx={{ marginBottom: 3 }} />)}
          <SimplifiedCard title='Testing instances' sx={{ marginBottom: 3 }}>
            <Instances deployment={deployment} displayWarning={displayWarning} />
          </SimplifiedCard>
          <SimplifiedCard title='Additional options'>
            <Checkboxes app={deployment.backendApp} deployment={deployment} />
          </SimplifiedCard>

          {condensedLayout && <Warnings warnings={displayedWarnings} closeWarning={closeWarning} sx={{ mt: 3 }} />}

          <ButtonContainer>
            <Button
              variant='contained'
              color={error ? 'secondary' : 'primary'}
              disabled={!!loading}
              startIcon={loading ? <CircularProgress size={24} color='inherit' /> : <RocketLaunch />}
              onClick={startDeployment}
            >
              Start deployment
            </Button>
          </ButtonContainer>
        </Grid>
      </Grid>
    </Container>
  )
}

export default observer(Create)

import React from 'react'
import { observer } from 'mobx-react'
import { AppBar, Toolbar, Container, Link, Button, useMediaQuery, IconButton } from '@mui/material'
import Cellphone from 'mdi-material-ui/CellphoneArrowDown'
import ServerNetwork from 'mdi-material-ui/ServerNetwork'
import FormatListChecks from 'mdi-material-ui/FormatListChecks'
import Plus from 'mdi-material-ui/Plus'
import { WHITE, TRANSPARENT } from '../constants/brand'
import createLinkButtonProps from '../utils/create_link_button_props'
import styled from '../utils/styled'
import { useStore } from '../store'
import Logo from './logo'
import { useTheme } from '@mui/material/styles'

const StyledAppBar = styled(React.memo(({ backgroundColor, ...props }) => <AppBar {...props} />))`
  background: ${(props) => props.backgroundColor || TRANSPARENT};
  transition: 0.2s;
  position: relative;
`

const StyledLogo = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(1)};
  width: 40px;
  height: 40px;
  transition: 0.2s;
`

const Title = styled(Link).attrs(() => ({
  variant: 'h6',
  color: 'inherit',
  underline: 'none'
}))`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

const ToolbarButton = ({ icon, showIcon = true, ...props }) => {
  const theme = useTheme()
  const onlyIcon = useMediaQuery(theme.breakpoints.down('md'))

  if (onlyIcon) {
    return (
      <IconButton {...props} sx={{ ml: 2 }} color='inherit'>
        {icon}
      </IconButton>
    )
  }

  return <Button startIcon={showIcon ? icon : undefined} {...props} sx={{ ml: 2 }} color='inherit' />
}

const AdditionalBackgroundEffect = styled(
  React.memo(({ effectHeight, backgroundColor, ...props }) => <div {...props} />)
)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transform-origin: top;
  transform: scaleY(${(props) => props.effectHeight || '55'});
  transition: 0.2s;
  background: ${(props) => props.backgroundColor || TRANSPARENT};
  z-index: -1;
`

export default observer(() => {
  const {
    ui: { title, appbarColor, additionalEffectColor, extraBackgroundHeight }
  } = useStore()
  const theme = useTheme()
  const showTitle = useMediaQuery(theme.breakpoints.up('md'))

  const titleButtonProps = createLinkButtonProps('/')
  const appsButtonProps = createLinkButtonProps('/apps')
  const deploymentButtonProps = createLinkButtonProps('/create')
  const myDeploymentsButtonProps = createLinkButtonProps('/list')
  const allInstancesButtonProps = createLinkButtonProps('/instances')

  return (
    <StyledAppBar position='static' color='primary' elevation={0} backgroundColor={appbarColor}>
      <Container>
        <Toolbar disableGutters>
          <Title {...titleButtonProps}>
            <StyledLogo color={WHITE} />
            {showTitle && (title || 'Squirrel')}
          </Title>
          <ToolbarButton icon={<Cellphone />} {...appsButtonProps}>
            Apps
          </ToolbarButton>
          <ToolbarButton icon={<ServerNetwork />} {...allInstancesButtonProps}>
            Instances
          </ToolbarButton>
          <ToolbarButton icon={<FormatListChecks />} {...myDeploymentsButtonProps}>
            Deployments
          </ToolbarButton>
          <ToolbarButton showIcon={false} icon={<Plus />} variant='outlined' {...deploymentButtonProps}>
            New deployment
          </ToolbarButton>
        </Toolbar>
      </Container>
      <AdditionalBackgroundEffect backgroundColor={additionalEffectColor} effectHeight={extraBackgroundHeight} />
    </StyledAppBar>
  )
})

import React from 'react'
import { Card } from '@mui/material'
import Header from './header'
import styled from '../../utils/styled'

const StyledCard = styled(React.memo(({ gutter, ...props }) => <Card {...props} />))`
  width: 100%;
  margin-bottom: ${(props) => (props.gutter ? props.theme.spacing(3) : 0)};
`

const InstanceCard = ({ name, country, number, showOpenButton, children, gutter, status, withLink = false }) => (
  <StyledCard gutter={gutter}>
    <Header
      name={name}
      country={country}
      number={number}
      showOpenButton={showOpenButton}
      status={status}
      withLink={withLink}
    />
    {children}
  </StyledCard>
)

export default InstanceCard

import React from 'react'
import PropTypes from 'prop-types'
import Title from './title'
import AppBar from './app_bar'
import CssBaseline from '@mui/material/CssBaseline'
import styled from '../utils/styled'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html {
    min-height: 100vh;
  }
  
  body {
    display: flex;
    flex: 1;
    min-height: 100vh;
    transition: background 0.15s linear;
  }
  
  #root {
    display: flex;
    flex: 1;
    min-height: 100vh;
  }
`

const Container = styled('div')`
  width: 100%;
`

const ContentContainer = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2)} 0;
`

const AppContainer = ({ children }) => (
  <>
    <Container>
      <Title title={null} />
      <AppBar />
      <ContentContainer>
        <CssBaseline />
        {children}
      </ContentContainer>
    </Container>
    <GlobalStyles />
  </>
)

AppContainer.propTypes = {
  children: PropTypes.node
}

export default AppContainer

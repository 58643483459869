import React from 'react'
import ServerRemove from 'mdi-material-ui/ServerRemove'
import ServerPlus from 'mdi-material-ui/ServerPlus'

import { MenuItem, ListItemIcon, ListItemText, CircularProgress } from '@mui/material'
import ConfirmButton from '../../confirm_button'
import useAction from './use_action'
import { confirmProps } from '../../../constants/instances'

import { useTheme } from '@mui/material/styles'

const InstanceActionMenuItem = ({ name, status, onActioned = () => null, variant = 'outlined', ...props }) => {
  const { loading, onStart, onStop } = useAction({ name, status, onActioned })
  const theme = useTheme()

  const runningOrStopping = status === 'running' || status === 'stopping'
  const stoppedOrStarting = status === 'stopped' || status === 'starting'

  if (!runningOrStopping && !stoppedOrStarting) {
    return null
  }

  const icon = () => {
    if (loading) {
      return <CircularProgress size={14} color='secondary' />
    } else if (runningOrStopping) {
      return <ServerRemove />
    } else {
      return <ServerPlus />
    }
  }

  const color = runningOrStopping ? theme.palette.error.main : theme.palette.success.main

  return (
    <ConfirmButton
      {...confirmProps({ name, status })}
      Component={MenuItem}
      onClick={runningOrStopping ? onStop : onStart}
      disabled={loading}
      {...props}
    >
      <ListItemIcon fontSize='small' sx={{ color }}>
        {icon()}
      </ListItemIcon>
      <ListItemText sx={{ color }}>{runningOrStopping ? 'Stop instance' : 'Start instance'}</ListItemText>
    </ConfirmButton>
  )
}

export default InstanceActionMenuItem

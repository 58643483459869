import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import { SnackbarProvider, closeSnackbar } from 'notistack'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { IconButton } from '@mui/material'
import Close from 'mdi-material-ui/Close'
import { ConfigContext } from './config'
import store, { StoreContext } from './store'
import client from './api/client'
import AppContainer from './components/app_container'
import { PRIMARY, GRAY_4, CTA_PURPLE, CTA_GREEN, GOLD_1, GOLD_2, TEAL_1, CTA_SALMON, TEXT } from './constants/brand'
import ErrorBoundary from './components/error_boundary'
import SessionManager from './components/session_manager'

import Index from './components/pages/main'
import Create from './components/pages/create'
import Build from './components/pages/build'
import List from './components/pages/list'
import Instances from './components/pages/instances'
import Instance from './components/pages/instance'
import Apps from './components/pages/apps'
import MigrationDialog from './components/migration_dialog'

import '@fontsource/roboto'
import './sentry'

const theme = createTheme({
  typography: {
    fontSize: 14
  },
  palette: {
    primary: {
      main: PRIMARY
    },
    secondary: {
      main: CTA_PURPLE
    },
    error: {
      main: CTA_SALMON
    },
    warning: {
      dark: GOLD_1,
      main: GOLD_2
    },
    info: {
      main: TEAL_1
    },
    success: {
      main: CTA_GREEN
    },
    background: {
      default: GRAY_4
    },
    text: {
      default: TEXT
    },
    contrastThreshold: 2.4
  }
})

const App = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ApolloProvider client={client}>
        <SnackbarProvider
          action={(snackbarId) => (
            <IconButton onClick={() => closeSnackbar(snackbarId)} size='small' color='inherit'>
              <Close />
            </IconButton>
          )}
          maxSnack={16}
        >
          <StoreContext.Provider value={store}>
            <ConfigContext.Provider value={props}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <ErrorBoundary>
                    <SessionManager>
                      <Router>
                        <AppContainer>
                          <Switch>
                            <Route path='/build/:id'>
                              <Build />
                            </Route>
                            <Route path='/create'>
                              <Create />
                            </Route>
                            <Route path='/list/:page?'>
                              <List />
                            </Route>
                            <Route path='/instances/:name'>
                              <Instance />
                            </Route>
                            <Route path='/instances'>
                              <Instances />
                            </Route>
                            <Route path='/apps/:appName?'>
                              <Apps />
                            </Route>
                            <Route path='/'>
                              <Index />
                            </Route>
                          </Switch>
                          <MigrationDialog />
                        </AppContainer>
                      </Router>
                    </SessionManager>
                  </ErrorBoundary>
                </ThemeProvider>
              </StyledEngineProvider>
            </ConfigContext.Provider>
          </StoreContext.Provider>
        </SnackbarProvider>
      </ApolloProvider>
    </LocalizationProvider>
  )
}

export default App

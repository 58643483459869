import { gql } from '@apollo/client'

export const DEPLOYMENT_CREATE = gql`
  mutation deploymentCreate(
    $username: String!
    $jiraIssue: String
    $jenkinsBuilds: [JenkinsBuildInput!]
    $semaphoreBuilds: [SemaphoreBuildInput!]
    $reserve: Boolean
    $reserveUntil: ISO8601DateTime
  ) {
    deploymentCreate(
      username: $username
      jiraIssue: $jiraIssue
      jenkinsBuilds: $jenkinsBuilds
      semaphoreBuilds: $semaphoreBuilds
      reserve: $reserve
      reserveUntil: $reserveUntil
    ) {
      deployment {
        id
      }
    }
  }
`

export const FETCH_DEPLOYMENTS = gql`
  query deployments($username: String, $page: Int, $limit: Int) {
    deployments(username: $username, page: $page, limit: $limit) {
      nodes {
        id
        username
        jiraIssue
        status
        builds {
          nodes {
            instance {
              name
            }
          }
        }
        updatedAt
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export const FETCH_DEPLOYMENT = gql`
  query deployments($id: ID!) {
    deployment(id: $id) {
      id
      username
      jiraIssue
      status
      builds {
        nodes {
          app
          appType
          progress
          instance {
            name
            country
            number
          }
          revision
          status
          error
          buildUrl
          appUrl
          ... on JenkinsBuild {
            detailsUrl
          }
        }
      }
      createdAt
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@mui/material'
import { useHistory } from 'react-router'
import { useLazyQuery } from '@apollo/client'
import { FETCH_JIRA_VERSIONS } from '../../../api/jira'
import { useSnackbar } from 'notistack'

const RedeployButton = ({ id, issue, instances, disabled, ...props }) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [getIssueVersions, { loading }] = useLazyQuery(FETCH_JIRA_VERSIONS)

  const redeploy = () => {
    ;(async function () {
      try {
        const url = typeof issue === 'string' ? issue : issue.url
        const versions = await getIssueVersions({ variables: { key: url } })

        if (versions.data?.jiraIssueVersions) {
          history.push('/create', { ...versions.data.jiraIssueVersions, url, instances })
        }
      } catch (e) {
        enqueueSnackbar(e.message, { variant: 'error' })
      }
    })()
  }

  return (
    <Button
      color='inherit'
      size='small'
      disabled={loading || disabled}
      onClick={redeploy}
      variant='outlined'
      startIcon={loading && <CircularProgress size={24} color='inherit' />}
      {...props}
    >
      Redeploy
    </Button>
  )
}

RedeployButton.propTypes = {
  id: PropTypes.string
}

export default RedeployButton

import React from 'react'
import PropTypes from 'prop-types'
import LoadingButton from '@mui/lab/LoadingButton'
import DatabaseSync from 'mdi-material-ui/DatabaseSync'
import DatabaseAlert from 'mdi-material-ui/DatabaseAlert'
import Tooltip from '@mui/material/Tooltip'
import ConfirmButton from '../../confirm_button'
import useRefreshDb from './use_refresh_db'

const RefreshDbButton = ({ name, disabled, variant = 'outlined', ...props }) => {
  const { loading, warning, onClick } = useRefreshDb({ name })

  const tooltip = disabled
    ? 'Testing Instance is not running'
    : warning
      ? 'There was an error refreshing the database'
      : 'Refresh database'

  return (
    <Tooltip title={tooltip} placement='top'>
      <span>
        <ConfirmButton
          Component={LoadingButton}
          variant={variant}
          color={warning ? 'warning' : 'secondary'}
          loading={loading}
          disabled={disabled}
          onClick={onClick}
          startIcon={warning ? <DatabaseAlert /> : <DatabaseSync />}
          messageContent={
            <>
              Are you sure you want to <b>refresh the database</b> on {name}?
            </>
          }
          buttonYesTitle='Yes'
          buttonNoTitle='No'
          loadingPosition='start'
          {...props}
        >
          {loading ? 'Database is refreshing' : 'Refresh Database'}
        </ConfirmButton>
      </span>
    </Tooltip>
  )
}

RefreshDbButton.propTypes = {
  name: PropTypes.string.isRequired
}

export default RefreshDbButton

import React from 'react'
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import CalendarCheck from 'mdi-material-ui/CalendarCheck'
import CalendarRemove from 'mdi-material-ui/CalendarRemove'
import { useStore } from '../../../store'
import Dialog from './dialog'
import useReserve from './use_reserve'

const ReserveMenuItem = ({ name, activeReservation, onClick, ...props }) => {
  const {
    session: {
      currentUser: { email }
    }
  } = useStore()

  const { dialogProps, triggerProps } = useReserve({ name, activeReservation, onClick })

  const icon = () => {
    if (activeReservation?.username === email) {
      return <CalendarRemove />
    }
    return <CalendarCheck />
  }

  const label = () => {
    if (activeReservation?.username === email) {
      return 'Release reservation'
    }
    return 'Reserve'
  }

  return (
    <>
      <MenuItem {...props} {...triggerProps}>
        <ListItemIcon fontSize='small'>{icon()}</ListItemIcon>
        <ListItemText>{label()}</ListItemText>
      </MenuItem>
      <Dialog {...dialogProps} />
    </>
  )
}

export default ReserveMenuItem

import React from 'react'
import { Paper, Typography, Chip, useMediaQuery, Box } from '@mui/material'
import parseISO from 'date-fns/parseISO'
import formatRelative from 'date-fns/formatRelative'
import styled from '../../utils/styled'
import Avatar from './avatar'
import Status from './status'
import { GRAY_2, PRIMARY } from '../../constants/brand'
import { useStore } from '../../store'
import { useHistory } from 'react-router'
import User from '../user'
import { useTheme } from '@mui/material/styles'

const Container = styled(Paper)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(0)};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  padding: ${(props) => props.theme.spacing(1)};
  ${(props) => props.$clickable && 'cursor: pointer;'}
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const AvatarContainer = styled('div')`
  margin-right: ${(props) => props.theme.spacing(2)};
`

const Title = Box

const Name = styled(Typography).attrs(() => ({
  variant: 'h6'
}))`
  margin-right: ${(props) => props.theme.spacing(2)};
  min-width: 90px;
  line-height: 1.2;
  display: flex;
  align-items: center;
`

const Reservation = styled(Typography).attrs(() => ({
  variant: 'caption',
  display: 'block'
}))`
  line-height: 1.1;
  color: ${({ $available }) => ($available ? PRIMARY : GRAY_2)};
  font-weight: ${({ $available }) => ($available ? 700 : 400)};
`

const label = (activeReservation, noExtraLabel) => {
  if (!activeReservation) {
    return 'Available for reservation'
  }
  const { activeUntil } = activeReservation
  const activeUntilLabel = activeUntil ? ` until ${formatRelative(parseISO(activeUntil), new Date())}` : ''

  return (
    <>
      {(!noExtraLabel || !activeUntil) && (
        <b>
          <User email={activeReservation.username} />
        </b>
      )}
      {activeUntilLabel}
    </>
  )
}

const Instance = ({ instance, clickable = true, noExtraLabel = false, actionButton = null, children, ...props }) => {
  const {
    session: {
      currentUser: { email }
    }
  } = useStore()
  const theme = useTheme()
  const smallFlagVariant = useMediaQuery(theme.breakpoints.down('md'))

  const { name, country, status, activeReservation } = instance
  const history = useHistory()

  return (
    <Container
      onClick={clickable ? () => history.push(`/instances/${name}`, { initialInstanceData: instance }) : undefined}
      $clickable={clickable}
      {...props}
    >
      <Row>
        <Row>
          {!smallFlagVariant && (
            <AvatarContainer>
              <Avatar country={country} elevation={0} />
            </AvatarContainer>
          )}
          <Title>
            <Name>
              {smallFlagVariant && (
                <Avatar country={country} elevation={0} size={20} sx={{ display: 'inline-flex', mr: 1 }} />
              )}
              {name}
            </Name>
            <Reservation $available={!activeReservation} sx={smallFlagVariant ? { ml: '28px' } : {}}>
              {label(activeReservation, noExtraLabel)}
            </Reservation>
          </Title>
        </Row>
        {!noExtraLabel && !activeReservation && (
          <Chip size='small' variant='outlined' label='Free' color='primary' sx={{ mr: 1 }} />
        )}
        {!noExtraLabel && activeReservation?.username === email && (
          <Chip size='small' variant='outlined' label='You' color='info' sx={{ mr: 1 }} />
        )}
        <Status status={status} label={smallFlagVariant ? undefined : status} />
        {actionButton}
      </Row>
      {children}
    </Container>
  )
}

export default Instance

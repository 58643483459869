import React from 'react'
import PropTypes from 'prop-types'
import DatabaseSync from 'mdi-material-ui/DatabaseSync'
import DatabaseAlert from 'mdi-material-ui/DatabaseAlert'
import ConfirmButton from '../../confirm_button'
import useRefreshDb from './use_refresh_db'
import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from '@mui/material'

const RefreshDbMenuItem = ({ name, disabled, ...props }) => {
  const { loading, warning, onClick } = useRefreshDb({ name })

  return (
    <>
      <ConfirmButton
        Component={MenuItem}
        disabled={loading || disabled}
        messageContent={
          <>
            Are you sure you want to <b>refresh the database</b> on {name}?
          </>
        }
        buttonYesTitle='Yes'
        buttonNoTitle='No'
        onClick={onClick}
        {...props}
      >
        <ListItemIcon fontSize='small'>
          {loading ? <CircularProgress size={18} sx={{ ml: 0.25 }} /> : warning ? <DatabaseAlert /> : <DatabaseSync />}
        </ListItemIcon>
        <ListItemText>{loading ? 'Database is refreshing' : 'Refresh Database'}</ListItemText>
      </ConfirmButton>
    </>
  )
}

RefreshDbMenuItem.propTypes = {
  name: PropTypes.string.isRequired
}

export default RefreshDbMenuItem

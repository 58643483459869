import React from 'react'
import { Container, Grid, useMediaQuery } from '@mui/material'
import Title from '../../title'
import JiraForm from '../../jira_form'
import { PRIMARY } from '../../../constants/brand'
import LatestDeployments from './latest_deployments'
import { useStore } from '../../../store'
import ReservedInstances from './reserved_instances'
import { useTheme } from '@mui/material/styles'

const Index = () => {
  const store = useStore()
  const username = store.session.currentUser.email
  const theme = useTheme()
  const jiraFirst = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Container>
      <Title colors={[PRIMARY, PRIMARY]} />
      <Grid container spacing={3}>
        <Grid item md={7} sm={12} xs={12}>
          {jiraFirst && <JiraForm sx={{ mb: 3 }} />}
          {!!username && <LatestDeployments username={username} />}
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          {!jiraFirst && <JiraForm sx={{ mb: 3 }} />}
          {!!username && <ReservedInstances username={username} />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Index

import React from 'react'
import PropTypes from 'prop-types'
import { BLACK } from '../constants/brand'
import logo from '../images/logo.svg'

const Logo = ({ color = BLACK, ...props }) => <img src={logo} {...props} />

Logo.propTypes = {
  color: PropTypes.string
}

export default Logo

import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { styled, useTheme } from '@mui/material/styles'
import { observer } from 'mobx-react'
import {
  Box,
  CircularProgress,
  Collapse,
  Grid,
  Typography,
  Container,
  List,
  Paper,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import InstanceCard from '../../instance_card'
import { getFavicon, getDeploymentStatus, getColors } from '../../../utils/build'
import Title from '../../title'
import JiraIssue from '../../jira_issue'
import StatusLabel from './status_label'
import NotFound from './not_found'
import Build from './build'
import { useQuery } from '../../../utils/apollo'
import { FETCH_DEPLOYMENT } from '../../../api/deployments'
import { FAILURE, SUCCESS } from '../../../constants/statuses'
import groupBy from 'lodash/groupBy'
import relativeDate from 'tiny-relative-date'
import User from '../../user'

const BuildList = styled(List)`
  & .MuiListItem-container {
    display: flex;
    align-items: center;
  }
`

const ProgressContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(30)};
`

const StyledCollapse = styled(Collapse)`
  width: 100%;
`

const Cards = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
  justify-content: space-between;
`

const DeploymentInfo = styled('div')`
  padding: ${(props) => props.theme.spacing(2)};
  margin: ${(props) => props.theme.spacing(0, 0, 3, 0)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Details = ({ deployment, status, id, builds }) => (
  <>
    <Paper elevation={1}>
      <DeploymentInfo>
        <Box>
          <Typography variant='subtitle2'>
            Requested by{' '}
            <b>
              <User email={deployment.username} />
            </b>
          </Typography>
          <Typography variant='subtitle2'>
            <Tooltip title={new Date(deployment.createdAt).toLocaleString()}>
              <span>{relativeDate(deployment.createdAt)}</span>
            </Tooltip>
          </Typography>
        </Box>
        <StatusLabel status={status} />
      </DeploymentInfo>
    </Paper>
    {deployment.jiraIssue && (
      <JiraIssue id={id} issue={deployment.jiraIssue} instances={Object.keys(builds).map((id) => Number(id))} />
    )}
  </>
)

const BuildPage = () => {
  const { id } = useParams()

  const { data, error, loading, stopPolling } = useQuery(FETCH_DEPLOYMENT, {
    variables: { id },
    pollInterval: 1000
  })

  const theme = useTheme()
  const detailsFirst = useMediaQuery(theme.breakpoints.down('md'))

  const deployment = data?.deployment
  const status = getDeploymentStatus(deployment)

  useEffect(() => {
    if (deployment === null || status === FAILURE || status === SUCCESS || error) {
      stopPolling()
    }
  }, [status])

  if (deployment === null || error) {
    return <NotFound />
  }

  const builds = groupBy(deployment?.builds?.nodes || [], (build) => build?.instance?.name)

  const [appbarColor, extraBackgroundColor] = getColors(status)

  return (
    <Container>
      <Title title={`Deployment #${id}`} favicon={getFavicon(status)} colors={[appbarColor, extraBackgroundColor]} />

      {loading && (
        <ProgressContainer>
          <CircularProgress />
        </ProgressContainer>
      )}

      <StyledCollapse in={!loading && !!deployment}>
        <Cards>
          {status && (
            <Grid container spacing={detailsFirst ? 0 : 3}>
              {detailsFirst && (
                <Grid item md={5} sm={12} xs={12}>
                  {deployment && <Details id={id} deployment={deployment} status={status} builds={builds} />}
                </Grid>
              )}
              <Grid item md={7} sm={12} xs={12}>
                {Object.entries(builds).map(([instanceId, builds]) => (
                  <InstanceCard instance={instanceId} key={instanceId} {...builds[0].instance} gutter withLink>
                    <BuildList dense>
                      {builds.map((build) => (
                        <Build key={`${build.app}.${build.appType}`} build={build} instanceId={instanceId} />
                      ))}
                    </BuildList>
                  </InstanceCard>
                ))}
              </Grid>
              {!detailsFirst && (
                <Grid item md={5} sm={12} xs={12}>
                  {deployment && <Details id={id} deployment={deployment} status={status} builds={builds} />}
                </Grid>
              )}
            </Grid>
          )}
        </Cards>
      </StyledCollapse>
    </Container>
  )
}

export default observer(BuildPage)

import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import styled from '../utils/styled'

// prettier-ignore
const StyledCard = styled(Card)`
  ${(props) => (
    props.$noExtraBottomPadding
? `
      & .MuiCardContent-root {
        padding-bottom: ${props.theme.spacing(2)}
      }
    `
: '')}
`

const SimplifiedCard = ({ title, children, noExtraBottomPadding, ...props }) => (
  <StyledCard $noExtraBottomPadding={noExtraBottomPadding} {...props}>
    <CardContent>
      {title && (
        <Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
          {title}
        </Typography>
      )}
      {children}
    </CardContent>
  </StyledCard>
)
export default SimplifiedCard

import React from 'react'
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow as UnstyledTableRow,
  TableCell as UnstyledTableCell,
  IconButton,
  Tooltip,
  Box,
  Skeleton,
  useMediaQuery,
  ListItemIcon,
  MenuList,
  ListItemText
} from '@mui/material'
import { useParams, useLocation, Link } from 'react-router-dom'
import Instance from '../../instance'
import { useQuery } from '../../../utils/apollo'
import { FETCH_INSTANCE } from '../../../api/instances'
import Title from '../../title'
import { TEAL_1 } from '../../../constants/brand'
import FakeInstance from '../instances/fake_instance'
import SimplifiedCard from '../../simplified_card'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import ReserveButton from '../../instance/reserve/button'
import styled from '../../../utils/styled'
import reverse from 'lodash/reverse'
import Web from 'mdi-material-ui/Web'
import Cellphone from 'mdi-material-ui/Cellphone'
import { NATIVE } from '../../../constants/app_types'
import ActionButton from '../../instance/action/button'
import Branch from '../instances/branch'
import Revision from '../../revision'
import RefreshDbButton from '../../instance/refresh_db/button'
import relativeDate from 'tiny-relative-date'
import User from '../../user'
import CloudUploadOutline from 'mdi-material-ui/CloudUploadOutline'
import { useTheme } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import DotsVertical from 'mdi-material-ui/DotsVertical'
import ReserveMenuItem from '../../instance/reserve/menu_item'
import InstanceActionMenuItem from '../../instance/action/menu_item'
import RefreshDbMenuItem from '../../instance/refresh_db/menu_item'

const TableRow = styled(UnstyledTableRow)`
  ${(props) =>
    !props.withBottomBorder &&
    `
    &:last-child td, &:last-child th {
      border: 0
    }
  `}
`

const TableCell = styled(UnstyledTableCell)`
  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ${(props) =>
    props.$collapse &&
    `
    padding-left: 0;
    padding-right: 0;
    width: 0;
    white-space: pre;
  `}
`

const TypographyWithBold = styled(Typography)`
  ${(props) =>
    props.$bold &&
    `
    font-weight: 700;
  `}
`

const InstanceButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.spacing(2)};
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  margin: ${(props) => props.theme.spacing(1, -1, 0, -1)};
  padding: ${(props) => props.theme.spacing(1, 1, 0, 1)};
`

const InstanceButtonGroup = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
`

const InstanceButtons = ({ instance }) => (
  <InstanceButtonsContainer>
    <InstanceButtonGroup>
      <Button
        size='small'
        component={Link}
        to={{ pathname: '/create', state: { instances: [{ name: instance.name }] } }}
        variant='outlined'
        startIcon={<CloudUploadOutline />}
      >
        New Deployment
      </Button>
      <ReserveButton size='small' name={instance.name} activeReservation={instance.activeReservation} />
    </InstanceButtonGroup>
    <InstanceButtonGroup>
      <RefreshDbButton size='small' name={instance.name} disabled={instance.status !== 'running'} />
      <ActionButton size='small' name={instance.name} status={instance.status} />
    </InstanceButtonGroup>
  </InstanceButtonsContainer>
)

/* eslint-disable react/jsx-handler-names */
const InstanceMenu = ({ instance }) => {
  return (
    <PopupState variant='popover' popupId='instance-menu'>
      {(popupState) => (
        <>
          <IconButton {...bindTrigger(popupState)}>
            <DotsVertical />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuList>
              <MenuItem component={Link} to={{ pathname: '/create', state: { instances: [{ name: instance.name }] } }}>
                <ListItemIcon>
                  <CloudUploadOutline />
                </ListItemIcon>
                <ListItemText>New Deployment</ListItemText>
              </MenuItem>
              <ReserveMenuItem
                name={instance.name}
                activeReservation={instance.activeReservation}
                onClick={popupState.close}
              />
              <RefreshDbMenuItem
                name={instance.name}
                disabled={instance.status !== 'running'}
                onClick={popupState.close}
              />
              <InstanceActionMenuItem name={instance.name} status={instance.status} onClick={popupState.close} />
            </MenuList>
          </Menu>
        </>
      )}
    </PopupState>
  )
}
/* eslint-enable react/jsx-handler-names */

const Buttons = ({ app, appType, appUrl, domain }) => {
  if (app === 'h2') {
    return (
      <>
        <Tooltip title='Open WWW for this instance'>
          <IconButton size='small' component='a' href={`https://www.${domain}`} target='_blank'>
            <Web />
          </IconButton>
        </Tooltip>

        <Tooltip title='Open Admin for this instance'>
          <IconButton size='small' component='a' href={`https://admin.${domain}`} target='_blank'>
            <Web />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  if (!appUrl) {
    return null
  }

  if (appType === NATIVE) {
    return (
      <>
        <Tooltip title='Show the QR code to open the app'>
          <IconButton size='small' component='a' href={appUrl} target='_blank'>
            <Cellphone />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  return (
    <>
      <Tooltip title='Open the app in new tab'>
        <IconButton size='small' component='a' href={appUrl} target='_blank'>
          <Web />
        </IconButton>
      </Tooltip>
    </>
  )
}

const ReservationsSkeleton = () => (
  <>
    <Skeleton variant='rectangular' sx={{ mt: 1.5, mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2 }} animation='wave' />
    <Skeleton variant='rectangular' sx={{ mb: 2, opacity: 0.75 }} animation='wave' />
  </>
)

const Instances = () => {
  const { name } = useParams()
  const location = useLocation()
  const theme = useTheme()
  const condensedLayout = useMediaQuery(theme.breakpoints.down('md'))

  const initialInstanceData = location.state?.initialInstanceData

  const { loading: loadingInBackground, data } = useQuery(FETCH_INSTANCE, { variables: { name }, pollInterval: 2000 })

  const partiallyLoaded = !!initialInstanceData && loadingInBackground
  const loading = !initialInstanceData && loadingInBackground
  const instance = data?.instance || initialInstanceData

  if (loading) {
    return (
      <Container>
        <Title title={`Testing Instances - ${name}`} colors={[TEAL_1, TEAL_1]} />
        <FakeInstance />
      </Container>
    )
  }

  // TODO: proper 404 page
  if (!loading && !instance) {
    return (
      <Container>
        <Title title={`Testing Instances - ${name}`} colors={[TEAL_1, TEAL_1]} />
        <Paper sx={{ padding: 2, textAlign: 'center' }}>Instance not found</Paper>
      </Container>
    )
  }

  const reservations = reverse(instance.reservations?.edges?.map(({ node }) => node) || [])
  const builds = (instance.latestJenkinsBuilds || []).concat(instance.latestSemaphoreBuilds || [])

  return (
    <Container>
      <Title title={`Testing Instances - ${name}`} colors={[TEAL_1, TEAL_1]} />

      <Instance
        instance={instance}
        sx={{ mb: 2 }}
        clickable={false}
        actionButton={condensedLayout && <InstanceMenu instance={instance} />}
      >
        {!condensedLayout && <InstanceButtons instance={instance} name={name} />}
      </Instance>

      <Grid container sx={{ mb: 2 }} spacing={2}>
        <Grid item xs={12} md={6}>
          <SimplifiedCard title='App versions' noExtraBottomPadding>
            <Table size='small'>
              <TableBody>
                {builds?.map(({ id, app, appType, appUrl, revision, branch, updatedAt }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Typography variant='body1' sx={{ pb: 0.5, pt: 0.5 }}>
                        {app}
                        {appType ? ` (${appType})` : ''}
                      </Typography>
                      <Typography
                        variant='subtitle2'
                        color='textSecondary'
                        sx={{ display: 'block', maxWidth: condensedLayout ? '200px' : '300px' }}
                        noWrap
                      >
                        {!!revision && <Revision app={app} revision={revision} label={branch} />}
                        {!revision && branch && <Branch app={app} branch={branch} />}
                      </Typography>
                      <Typography variant='overline' color='textSecondary' title={new Date(updatedAt).toLocaleString()}>
                        {relativeDate(parseISO(updatedAt), new Date())}
                      </Typography>
                    </TableCell>
                    <TableCell align='right' $collapse>
                      <Buttons app={app} appType={appType} appUrl={appUrl} domain={instance.domain} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </SimplifiedCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <SimplifiedCard title='Recent reservations' sx={{ mb: 2 }} noExtraBottomPadding>
            {partiallyLoaded && <ReservationsSkeleton />}
            {reservations.length > 0 && (
              <Table size='small' sx={{ mb: 1 }}>
                <TableBody>
                  {reservations.map((reservation) => (
                    <TableRow key={reservation.id} $active={reservation.active}>
                      <TableCell>
                        <TypographyWithBold variant='body2' $bold={reservation.active}>
                          <User email={reservation.username} />
                        </TypographyWithBold>
                      </TableCell>
                      <TableCell align='right'>
                        <TypographyWithBold variant='subtitle2' color='textSecondary' $bold={reservation.active}>
                          {format(parseISO(reservation.activeFrom), 'PPp')} -{' '}
                          {reservation.activeUntil ? format(parseISO(reservation.activeUntil), 'PPp') : 'no limit'}
                        </TypographyWithBold>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
            {!partiallyLoaded && reservations.length === 0 && (
              <Typography variant='body1'>This testing instance has never been reserved</Typography>
            )}
          </SimplifiedCard>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Instances

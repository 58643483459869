import { types } from 'mobx-state-tree'
import flatten from 'lodash/flatten'
import { BACKEND, NATIVE, WEB } from './constants/app_types'
import isWebNativeApp from './utils/is_web_native_app'

const CurrentUser = types.model('CurrentUser', {
  id: types.identifier,
  name: types.string,
  email: types.string,
  avatarUrl: types.string
})

const User = types.model('User', {
  email: types.identifier,
  name: types.string,
  avatarUrl: types.string
})

const Instance = types.model('Instance', {
  name: types.string
})

const BackendApp = types
  .model('BackendApp', {
    appType: types.literal(BACKEND),
    app: types.literal('h2'),
    revision: types.optional(types.string, ''),
    runMigrations: types.optional(types.boolean, true),
    refreshTranslations: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    update (revision) {
      self.revision = revision || ''
    },
    setRunMigrations (runMigrations) {
      self.runMigrations = runMigrations
    },
    setRefreshTranslations (refreshTranslations) {
      self.refreshTranslations = refreshTranslations
    }
  }))

const WebApp = types
  .model('MobileApp', {
    appType: types.literal(WEB),
    app: types.string,
    branch: types.optional(types.string, 'master')
  })
  .actions((self) => ({
    update (branch) {
      self.branch = branch || ''
    }
  }))

const NativeApp = types
  .model('NativeApp', {
    appType: types.literal(NATIVE),
    app: types.string,
    branch: types.optional(types.string, 'master')
  })
  .actions((self) => ({
    update (branch) {
      self.branch = branch || ''
    }
  }))

const App = types.union(BackendApp, WebApp, NativeApp)

const Deployment = types
  .model('Deployment', {
    instances: types.array(Instance),
    apps: types.array(App),
    reserve: types.optional(types.boolean, false),
    reserveUntil: types.maybeNull(types.number)
  })
  .views((self) => ({
    get semaphoreApps () {
      return self.apps.filter(({ appType }) => appType !== BACKEND)
    },
    get jenkinsApps () {
      return self.apps.filter(({ appType }) => appType === BACKEND)
    },
    get backendApp () {
      return self.jenkinsApps[0]
    },
    willDeployApp (deployedApp, deployedAppType) {
      return self.apps.some(({ app, appType }) => app === deployedApp && appType === deployedAppType)
    }
  }))
  .actions((self) => ({
    setInstances (instances) {
      self.instances = instances.map(({ name }) => ({ name }))
    },
    addApp (type, name, branchOrRevision) {
      const extra = branchOrRevision
        ? type === BACKEND
            ? { revision: branchOrRevision }
            : { branch: branchOrRevision }
        : {}
      self.apps.push({ appType: type, app: name, ...extra })
    },
    removeApp (model) {
      self.apps.remove(model)
    },
    setReserve (reserve) {
      self.reserve = !!reserve
    },
    setReserveUntil (reserveUntil) {
      self.reserveUntil = reserveUntil ? Number(reserveUntil) : reserveUntil
    },
    setFromJiraIssueVersions (versions, allApps) {
      self.apps.clear()
      versions.forEach(({ app, branch, revision }) => {
        if (app === 'h2') {
          self.apps.push({ appType: BACKEND, app, revision })
        } else if (isWebNativeApp(allApps, app)) {
          self.apps.push({ appType: WEB, app, branch })
          self.apps.push({ appType: NATIVE, app, branch })
        } else {
          self.apps.push({ appType: WEB, app, branch })
        }
      })
    },
    toGraphQLInput () {
      return {
        instances: self.instances.toJSON(),
        jenkinsBuilds: flatten(
          self.instances.map((instance) =>
            self.jenkinsApps.map((app) => ({
              instance: instance.name,
              revision: app.revision.trim(),
              runMigrations: app.runMigrations,
              refreshTranslations: app.refreshTranslations
            }))
          )
        ),
        semaphoreBuilds: flatten(
          self.instances.map((instance) =>
            self.semaphoreApps.map((app) => ({
              instance: instance.name,
              app: app.app,
              appType: app.appType,
              branch: app.branch.trim()
            }))
          )
        ),
        reserve: self.reserve,
        reserveUntil: self.reserveUntil ? new Date(self.reserveUntil).toISOString() : null
      }
    }
  }))

export { CurrentUser, User, Instance, Deployment, App, BackendApp, WebApp, NativeApp }

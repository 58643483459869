import React from 'react'
import { Typography, List, ListItem, ListItemIcon, Grid, Chip, Tooltip, Skeleton } from '@mui/material'
import { useHistory } from 'react-router'
import Check from 'mdi-material-ui/Check'
import ProgressClock from 'mdi-material-ui/ProgressClock'
import AlertOctagon from 'mdi-material-ui/AlertOctagon'
import Help from 'mdi-material-ui/Help'
import styled from '../../../utils/styled'
import uniq from 'lodash/uniq'
import relativeDate from 'tiny-relative-date'
import { FAILURE, IN_PROGRESS, PENDING, SUCCESS } from '../../../constants/statuses'

const StatusIcon = ({ status, ...props }) => {
  if (status === SUCCESS) {
    return <Check {...props} />
  } else if (status === PENDING || status === IN_PROGRESS) {
    return <ProgressClock {...props} />
  } else if (status === FAILURE) {
    return <AlertOctagon {...props} />
  } else {
    return <Help {...props} />
  }
}

const ListItemLink = ({ href, onClick, ...props }) => {
  const history = useHistory()
  return (
    <ListItem
      button
      component='a'
      {...props}
      href={href}
      onClick={(e) => {
        if (!e.ctrlKey && !e.metaKey) {
          e.preventDefault()
          history.push(href)
        }
        return onClick ? onClick(e) : undefined
      }}
    />
  )
}

const Deployments = styled(List).attrs(() => ({ component: 'nav' }))`
  opacity: ${(props) => (props.loading ? 0.6 : 1)};
`

const DeploymentId = styled(Typography).attrs(() => ({ variant: 'subtitle1', noWrap: true }))``

const InstancesContainer = styled(Typography).attrs(() => ({ variant: 'caption' }))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  ${(props) => props.theme.breakpoints.down('md')} {
    justify-content: flex-start;
  }
`

const Instances = ({ deployment }) => {
  const instances = uniq(deployment.builds.nodes.filter((build) => build?.instance).map((build) => build.instance.name))

  return (
    <InstancesContainer>
      {instances.map((instance) => (
        <Chip key={instance} size='small' label={instance} color='info' sx={{ marginRight: 0.5 }} />
      ))}
    </InstancesContainer>
  )
}

const ListItemContent = ({ icon, children, instances }) => (
  <>
    <ListItemIcon>{icon}</ListItemIcon>
    <Grid container justifyContent='space-between' alignItems='center'>
      <Grid item md={6} sm={12}>
        {children}
      </Grid>
      <Grid item md={6} sm={12}>
        {instances}
      </Grid>
    </Grid>
  </>
)

const DeploymentsList = ({ deployments, loading, expected }) => {
  if (loading) {
    return (
      <Deployments>
        {[...Array(expected)].map((_, i) => (
          <ListItem key={i}>
            <ListItemContent
              icon={<Skeleton variant='circular' width={24} height={24} />}
              instances={<Skeleton sx={{ width: 80, float: 'right' }} />}
            >
              <Skeleton sx={{ width: '100%', height: 26 }} />
              <Skeleton sx={{ width: '100%' }} />
            </ListItemContent>
          </ListItem>
        ))}
      </Deployments>
    )
  }

  if (!deployments) {
    return null
  }

  return (
    <Deployments>
      {deployments.map((deployment) => (
        <ListItemLink href={`/build/${deployment.id}`} key={deployment.id}>
          <ListItemContent
            icon={<StatusIcon status={deployment.status} />}
            instances={<Instances deployment={deployment} />}
          >
            <DeploymentId>
              Deployment #{deployment.id}
              {deployment.jiraIssue && (
                <Typography variant='body2' component='span' sx={{ marginLeft: 1 }}>
                  {deployment.jiraIssue}
                </Typography>
              )}
            </DeploymentId>
            <Typography variant='subtitle2'>
              <Tooltip title={new Date(deployment.updatedAt).toLocaleString()}>
                <span>{relativeDate(deployment.updatedAt)}</span>
              </Tooltip>
            </Typography>
          </ListItemContent>
        </ListItemLink>
      ))}
    </Deployments>
  )
}

export default DeploymentsList

import React from 'react'
import { observer } from 'mobx-react'
import { Dialog, DialogActions, DialogContent, DialogContentText, Typography, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useMutation } from '@apollo/client'
import styled from '../../utils/styled'
import { useStore } from '../../store'
import Heart from 'mdi-material-ui/Heart'
import google from './google.png'
import { CRIMSON_1, GRAY_3, SECONDARY_1 } from '../../constants/brand'
import { MIGRATE } from '../../api/session'

const Content = styled(DialogContent)`
  text-align: center;
  max-width: 460px;
`

const ImageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 165px;
  background: linear-gradient(${SECONDARY_1}, ${SECONDARY_1} 159px, rgba(0, 0, 0, 0.09) 160px, rgb(255, 255, 255) 100%);
  margin: -20px -25px 0 -25px;
  padding: 20px 25px 6px 25px;
`

const Image = styled('img').attrs(() => ({ src: google }))`
  width: 125px;
  height: 125px;
`

const Title = styled(Typography).attrs(() => ({ variant: 'h6' }))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.spacing(2)};
`

const HeartIcon = styled(Heart).attrs(() => ({ htmlColor: CRIMSON_1 }))`
  margin: 0 10px;
`

const Actions = styled(DialogActions)`
  text-align: center;
  margin-top: ${(props) => props.theme.spacing(2)};
`

const Migration = () => {
  const {
    session: { migrationFromTokens, oldJenkinsUsername, clearMigrationFromTokens }
  } = useStore()

  const [migrate, { loading }] = useMutation(MIGRATE)
  const onMigrate = () =>
    migrate({ variables: { jenkinsUsername: oldJenkinsUsername } }).then((result) => {
      if (result.data.migrate.success) {
        clearMigrationFromTokens()
      }
    })
  const onCancel = clearMigrationFromTokens

  return (
    <Dialog open={migrationFromTokens}>
      <Content>
        <ImageContainer>
          <Image />
        </ImageContainer>
        <Title>
          Squirrel <HeartIcon /> Google
        </Title>
        <DialogContentText>
          Squirrel has been updated to log in using Google.
          <br />
          Would you like to connect all deployments from <b>{oldJenkinsUsername}</b> to your new account?
        </DialogContentText>
        <Actions>
          <LoadingButton loading={loading} variant='contained' color='primary' autoFocus onClick={onMigrate}>
            Yes please!
          </LoadingButton>
          <Button variant='text' color='inherit' style={{ color: GRAY_3 }} onClick={onCancel}>
            No, thank you
          </Button>
        </Actions>
      </Content>
    </Dialog>
  )
}

export default observer(Migration)
